import React from 'react';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RecentUsers from '../../Components/RecentUsers/RecentUsers';
import SearchResults from '../../Components/SearchResults/SearchResults';
import { withRouter } from "react-router";
function HomePage({ history }) {
  return (
    <>
      <SearchResults />
      <RecentUsers /> 
      <div style={{display: 'flex', alignItems: 'flex-end',justifyContent:'center'}}>
        <a href="/users/"><ExpandMoreIcon/></a>
        <Button variant="contained" color="primary" onClick={() => history.push("/users/")}>
          show more
        </Button>
      </div>
    </>
  );
}


export default withRouter(HomePage);


import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Input } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withRouter } from "react-router";
import { restPasswordUserAction } from '../../store/actions/user';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import logo from '../../assets/images/logo.svg';
const useStyles = makeStyles(theme => ({
  root: {
   
    },
  container :{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height : 'calc(100vh - 100px)'
  },
  card :{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding : 20,
  }
}));

const RestPassword = ({ history, restPass, user }) => {
  const [formValid,setFormValid] = useState(false);
  const [email, setEmail] = useState('');
  useEffect(()=>{
    if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) setFormValid(true);
    else setFormValid(false);
  },[email]);
  const resetPasswordUser = ()=>{
    restPass(email);
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && formValid) {
      resetPasswordUser();
    }
  } 
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ minHeight: 'calc(100vh - 65px)' }}
        >
      <Card className={classes.card}>
      <img alt="logo" src={logo} width="100" height="100" />

        <Typography variant='h5'>
          Please enter your  your email 
        </Typography>
        <Typography variant='h5'>
          and hit the rest button
        </Typography>
        <br/>
        <Input placeholder='Email' required type='email' value={email} onChange={event => setEmail(event.target.value)} onKeyDown={(event)=> handleKeyDown(event)} />
		<br/>
		<Button variant="contained" color="primary" onClick={resetPasswordUser} disabled={!formValid} style={{marginTop : '20px'}}>
		Rest
		</Button>
		<br/>
		<Button color="primary" onClick={() => history.push('/register')}> You do not have an account ?</Button>
		<br/>
    <Button color="primary" onClick={() => history.push('/login')}> Login ?</Button>
		<Typography style={{color : 'red'}}>
			{user && user.error ? user.error : ''}
        </Typography>
        </Card>    
      </Grid>
    </>
  );
}
const mapStateToProps = state => {
  const {user } = state;
  return { user };
}

const mapDispatchToProps = dispatch => {
  return {
    restPass: (email, password) => dispatch(restPasswordUserAction(email, password)),
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RestPassword));
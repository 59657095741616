import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/images/logo.svg';
import PersonIcon from '@material-ui/icons/Person';
//import PersonAddIcon from '@material-ui/icons/PersonAdd';
//import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { logOutAction } from '../../store/actions/user';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  logoTitle : {
    display : 'flex',
    alignItems : 'center',
    justifyContent : 'space-around',
    height: 65,
	userSelect :'none',
	cursor: 'pointer',
  },
  logo : {
    height : 30,
    width : 30,
    marginLeft : 10,
  }
});

const NavigationDrawer = ({ showDrawer, closeDrawer, history, logOut, user}) => {
  const classes = useStyles();
  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={closeDrawer}
      onKeyDown={closeDrawer}
    >
      <div className={classes.logoTitle} onClick={ () => history.push('/')}>
      <Typography className={classes.title} variant="h6" noWrap>
            Cupid Spell
      </Typography>
      <img alt="Logo" className={classes.logo} src={logo}/>
      </div>
      <Divider />
      <List>
        <ListItem button onClick={ () => history.push('/messages')}>
          <ListItemIcon>
            <MailIcon />
          </ListItemIcon>
          <ListItemText primary="Messages" />
        </ListItem>
        <ListItem button onClick={ () => history.push('/notifications')}>
          <ListItemIcon>
          <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText primary="Notifications" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={ () => history.push('/profile')}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="My profile" />
        </ListItem>
        {/* <ListItem button onClick={ () => history.push('/account')}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Account Settings" />
        </ListItem> */}
		<ListItem button onClick={ () => history.push('/help')}>
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="Help" />
        </ListItem>
        <ListItem button onClick={()=>{logOut();  history.push('/');}}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout"  />
        </ListItem>
        
      </List>
    </div>
  );

  
  return (
    <div>
      <Drawer open={showDrawer} onClose={closeDrawer}>
        {sideList('left')}
      </Drawer>
    </div>
  );
}
const mapStateToProps = state => {
  const { user  } = state
  return { user };
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(logOutAction()),
  }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(NavigationDrawer));

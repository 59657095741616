import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/images/logo.svg';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { withRouter } from "react-router";
import InfoIcon from '@material-ui/icons/Info';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme =>({
  list: {
    width: 250,
  },
  logoTitle : {
    display : 'flex',
    alignItems : 'center',
    justifyContent : 'space-around',
    height: 65,
	userSelect :'none',
	cursor: 'pointer',
  },
  logo : {
    height : 30,
    width : 30,
    marginLeft : 10,
  },
}));

const NavigationDrawerGuest = ({ showDrawer, closeDrawer, history}) => {
  const classes = useStyles();
  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={closeDrawer}
      onKeyDown={closeDrawer}
    >
      <div className={classes.logoTitle} onClick={ () => history.push('/')}>
      <Typography className={classes.title} variant="h6" noWrap>
            Cupid Spell
      </Typography>
      <img alt="Logo" className={classes.logo} src={logo}/>
      </div>
      <Divider />
      <List>
        <ListItem button onClick={ () => history.push('/about')}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="About Cupid Spell" />
        </ListItem>
        <ListItem button onClick={ () => history.push('/contactus')}>
          <ListItemIcon>
            <ContactMailIcon />
          </ListItemIcon>
          <ListItemText primary="Contact us" />
        </ListItem>
        <ListItem button onClick={ () => history.push('/login')}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Login" />
        </ListItem>
        
      </List>
    </div>
  );

  
  return (
    <div>
      <Drawer open={showDrawer} onClose={closeDrawer}>
        {sideList('left')}
      </Drawer>
    </div>
  );
}
const mapStateToProps = state => {
  const { user  } = state
  return { user };
}

const mapDispatchToProps = dispatch => {
  return {
    someaction: () => dispatch({action : 'someAction'}),
  }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(NavigationDrawerGuest));

import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { withRouter } from "react-router";
import logo from '../../assets/images/logo.svg';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { searchUsersAction } from '../../store/actions/search';
import {connect} from 'react-redux';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logoTitle:{
	  display: 'none',
	  userSelect : 'none',
    cursor : 'pointer',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  logo : {
    height : 30,
    width : 30,
    marginLeft : 10,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

}));

const NavBarGuest = ({ openDrawer, history, searchUsers }) => {
  const classes = useStyles();
  const handleSearchUsers = event => {
    if (event.key === 'Enter' ){
      history.push('/');
      searchUsers(event.target.value);
    }
  }
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"  onClick={openDrawer}>
            <MenuIcon />
          </IconButton>
          <div className={classes.logoTitle} onClick={ () => history.push('/')}>
          <Typography className={classes.title} variant="h6" noWrap >
            Cupid Spell
          </Typography>
          <img alt="Logo" className={classes.logo} src={logo}/>
		  </div>
      <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onKeyDown = {handleSearchUsers}
            />
      </div>
		  <div className={classes.title} />
          <Button color="inherit" onClick={() => history.push('/login')}>Login</Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}



const mapDispatchToProps = dispatch => {
  return {
    searchUsers : (query) => dispatch(searchUsersAction(query)),
  }
}

export default connect(null,mapDispatchToProps)(withRouter(NavBarGuest));

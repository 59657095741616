import React, { useState } from 'react';
import Firebase from 'firebase';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
        profilePicture :{
         backgroundRepeat: 'no-repeat',
         backgroundSize:'100% 100%',
         width:'200px',
         height: '200px',
         display: 'flex',
         alignItems: 'flex-end',
         justifyContent: 'flex-end',
         borderRadius:'100%',
        },
        fileInputWrapper: {
          cursor:'pointer'
        },
        fileInput:{
          display:'none',
        },
        error : {
         alignSelf:'center',
         justifySelf:'center',
         backgroundColor:'#FFFFFF44',
         borderRadius:'1%',
         width:'90%',
         marginLeft:'5%',
         textAlign:'center',
        },
        saveIcon: {
         alignSelf:'center',
         justifySelf:'center',
         cursor: 'pointer',
        },
        loadingIcon:{
          alignSelf:'center',
         justifySelf:'center',
        }
}));


const ProfilePicture = ({src, userId, updateUserProfilePicture}) => {
        const [inputRef, setInputRef] = useState(null);
        const [tmpImage, setTmpImage]= useState('');
        const [progress, setProgress] = useState(false);
        const [uploadedImagePath, setUploadedImagePath] = useState('');
        const [imgFile, setImgFile] = useState('');
        const [imgFileExt, setImgFileExt] = useState('');
        const [error, setError] = useState('');
        const handleFileUpload = ()=>{
        var storageRef = Firebase.storage().ref();
        var ref = storageRef.child(`profile/${userId}.${imgFileExt}`);
        setProgress(true);
       ref.put(imgFile).then(function(snapshot) {
          snapshot.ref.getDownloadURL().then(function(downloadURL) {
                setUploadedImagePath(downloadURL);
                updateUserProfilePicture(downloadURL);
                setProgress(false);
                setImgFile(null);
          });
       });
}


   const handleFileInputChange = (event) => {
        setError('');
        if(event.target.files && event.target.files.length && event.target.files[0]){
          const file = event.target.files[0];
          const parts = file.name.split('.');
          const ext = parts[parts.length-1];
          if(!['png','jpg','jpeg'].includes(ext.toLowerCase())){
            setError('Error: only png, jpg extentions are allowed');
            return;
          }else{
                setImgFileExt(ext.toLowerCase());     
          }
          if(file.type.split('/')[0] !== 'image'){
                setError('Error: only images are allowed');
                return;
          }

          if(file.size > 2000000){
            setError('Error: Max file size 2 Mega');
             return;
          }
          setImgFile(file);
          var fr = new FileReader();
          fr.onload =  () => {
            setTmpImage(fr.result);
        }
        fr.readAsDataURL(file);
        }
   }
   
   const classes = useStyles();
   return (<>
            <div className={classes.profilePicture} style={{backgroundImage:`url(${tmpImage || uploadedImagePath || src})`}} >
             {error ? <p className={classes.error} style={{color:'red'}}>{error}</p> :null}
             {!error && progress ? <CircularProgress className={classes.loadingIcon} /> : null}      
             {!error && imgFile && !progress ?<SaveIcon fontSize="large"  color="primary" className={classes.saveIcon} onClick={handleFileUpload} /> : null}
             <div className={classes.fileInputWrapper}>
             <EditIcon onClick={()=> inputRef ? inputRef.click(): null} />
                 <input ref={setInputRef} type="file" onChange={handleFileInputChange} className={classes.fileInput}/>
             </div>
           </div>
           </>)

}

export default ProfilePicture;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import UserItem from '../../Components/UserItem/UserItem';
import { likeUserAction, unLikeUserAction } from '../../store/actions/users';
const useStyles = makeStyles(theme => ({
  users: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      height: theme.spacing(20),
    },
  },
}));

const AllUsersPage = ({ users , user, likeUser, unLikeUser }) => {
  //console.log("users : ", users);
  const handleLikeUser = (profileId)=>{
    likeUser(user.uid, profileId)
  }
  const handleUnLikeUser = (profileId)=>{
    unLikeUser(user.uid, profileId)
  }
  const recentUsers = [...users];
  const index = recentUsers.findIndex(el => el.id === user.uid);
  if(index !== -1) recentUsers.splice(index,1);
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
        <Container maxWidth='xl'>
        <div className={classes.users}>
          {recentUsers.map(user => <UserItem key={user.id} user={user} likeUser={handleLikeUser} unLikeUser={handleUnLikeUser} />)}
        </div>
      </Container>
    </>
  );
}

const mapStateToProps = state => {
  const { users, user } = state;
  return { users, user };
}

const mapDispatchToProps = dispatch => {
  return {
    likeUser: (myId, profileId) => dispatch(likeUserAction(myId, profileId)),
    unLikeUser: (myId, profileId) => dispatch(unLikeUserAction(myId, profileId)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(AllUsersPage);

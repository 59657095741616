import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import utils from '../../utils';
import DoneAllIcon from '@material-ui/icons/DoneAll';
const defaultPicture = '/default.png';

const useStyles = makeStyles(theme => ({
        messageItem : {
                display: 'flex',
                margin: '5px 0',
                borderRadius: '5px 15px 0px 5px',
                padding: '5px 0',
                alignItems : 'center',
                justifyContent: 'flex-start',
                
        },
        messageContent:{
                overflow : 'hidden',
                whiteSpace: 'normal', 
                wordWrap: 'break-word',
        },
        messageTime:{
                padding: '0 5px',
                alignSelf :'flex-end',
        },
        small: {
		width: theme.spacing(5),
                height: theme.spacing(5),
                margin: '0 10px',
        },
        messageSeen :{
                padding: '0 5px',
        },
        spaceFill :{
                flexGrow: '1',
        }
  }));

const CoversationList = ({conversation, user, correspondant}) => {
        const classes = useStyles();
        if (!conversation) return <></>;
        return  <>
                   {[...conversation.messageList].map((message, index) =>(
                        <div key={index} className={classes.messageItem} style={{backgroundColor: message.src === user.uid ? '#A9B6FF' : '#3f51b522', borderRadius: message.src === user.uid ? '5px 15px 5px 5px' : '5px 5px 15px 5px'}}>
                                   <Avatar alt={correspondant.userName} src={(message.src === user.uid ? user.pic : correspondant.pic) || defaultPicture } className={classes.small} />
                                   <p className={classes.messageContent}>{message.content}</p>
                                   <p className={classes.spaceFill}></p>
                                   {message.src === user.uid && message.seen ? <DoneAllIcon className={classes.messageSeen} /> : null}
                                   <p className={classes.messageTime}>{utils.converTimeToText(message.time)}</p>
                                   
                        </div>))}
                </>

}
export default CoversationList;
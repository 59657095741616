import Firebase from 'firebase';
export const UPDATE_USERS = 'UPDATE_USERS';
export const updateUsersAction = (users) =>({
	type: UPDATE_USERS,
	payload : users,
  });	

export const LIKE_USER = 'LIKE_USER';
export const likeUserAction = (myId, profileId) =>{
	if(myId && profileId){
		return  async (dispatch) => {
			Firebase.database().ref(`users/${profileId}/likes/${myId}`).set( {time: Firebase.database.ServerValue.TIMESTAMP, del: false, seen : false},(error) => {
				if (!error) 
					dispatch({
						type: LIKE_USER,
						payload :{myId, profileId},
					});
				})
			}
	}else{
		return {
			type : 'NO_ACTION',
		}

	}
}	
export const UNLIKE_USER = 'UNLIKE_USER';
export const unLikeUserAction = (myId, profileId) =>{
	if(myId && profileId){
			Firebase.database().ref(`users/${profileId}/likes/${myId}`).remove();
			return ({
				type: UNLIKE_USER,
				payload :{myId, profileId},
			});
	}else{
		return {
			type : 'NO_ACTION',
		}

	}
}	

export const VIEW_USER = 'VIEW_USER';
export const viewUserAction = (myId, profileId) =>{
	if(myId && profileId){
		return  async (dispatch) => {
			Firebase.database().ref(`users/${profileId}/views/${myId}`).set( {time : Firebase.database.ServerValue.TIMESTAMP, del: false, seen : false},(error) => {
				if (!error) 
					dispatch({
						type: VIEW_USER,
						payload :{myId, profileId},
					});
				})
			}
	}else{
		return {
			type : 'NO_ACTION',
		}

	}
}	


export const GET_USER_FROM_DB = 'GET_USER_FROM_DB';
export const UPDATE_USER = 'UPDATE_USER';
export const getUserFromDBAction = (profileId) =>{
	if(profileId){
		return  async (dispatch) => {
			Firebase.database().ref(`users/${profileId}`).once('value').then(snapshot => {
				var user = snapshot.val();
				//console.log(Object.assign(user,{id : profileId}))	
				var userToAdd = {};
				if(user) userToAdd = Object.assign(user,{id : profileId});
				else userToAdd = Object.assign({},{id : profileId});
				 dispatch({
					type: UPDATE_USER,
				 	payload : userToAdd,
				 });
			}).catch(error => {
				console.log('error', error);
				// dispatch({
				// 	type: 'NO_ACTION',
				// 	payload : error,
				// });
			});
			
	}
}
}	



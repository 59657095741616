import React from 'react';
import RestPassword from '../../Components/RestPassword/RestPassword';
function RestPasswordPage() {
  return (
    <>
      <RestPassword />
    </>
  );
}

export default RestPasswordPage;

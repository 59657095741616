import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import logo from '../../assets/images/logo.svg';

const useStyles = makeStyles(theme => ({
  root: {
   
    },
  container :{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card :{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding : 20,
    margin : 50,
  }
}));

function AboutPage() {
 
  const classes = useStyles();
  return (
    <>
    <CssBaseline />
      <div className={classes.container} >
      <Card className={classes.card}>
      <Typography variant="h2"><Box textAlign="center">CupidSpell</Box></Typography>    
      <img alt="logo" src={logo} width="100" height="100" />
      <Typography variant="h4"><Box textAlign="center">About Us</Box></Typography>
      <Typography variant="h6" color="textSecondary"><Box textAlign="justify">
      CupidSpell.com is at the forefront of the online dating world and offers an unmatched global reach. Launched in 1993, our company now covers more than 32 countries and offers the technology that lets members take dating further than ever. Our ambition is to connect singles no matter where they are and inspire romance on a global scale. The experience we have built since our launch has given us a real insight into what people look for when it comes to dating. We offer the most direct and successful approach to meeting singles anywhere in the world.
      </Box></Typography>
      <Typography variant="h6" color="textSecondary"><Box textAlign="justify">
      We have a global presence with a particular emphasis on Europe, Asia, North America and Latin America. That’s because we know the world of romance has changed. Now singles want to search further afield for their special someone. And amolatina.com is in the perfect position to introduce matches from a range of places and cultures.
      </Box></Typography>
      <Typography variant="h6" color="textSecondary"><Box textAlign="justify">
      Everything we do is aimed at bringing singles together and helping them start a relationship with a special person. We have developed this skill into a fine art! Our effective approach helps couples find each other on our website. That’s why we continue to be inspired by the love of those who use amolatina.com.
      Our Approach
      </Box></Typography>
      <Typography variant="h6" color="textSecondary"><Box textAlign="justify">
      Here is the place for singles across the world to find each other and begin a relationship using a range of communication features. We provide the platform and tools for members to get in touch. Like any dating opportunity, it all starts by creating a profile with a photo and sharing personal details. The next step is to search through the membership database and quickly see other people who have joined. One of the highlights of amolatina.com is to read about members both locally and from other parts of the world. Once members are interested in each other they can talk using a direct message system and Live Chat. All activity takes place on a secure, reliable and safe network that guarantees the privacy and safety of every member. And our Customer Service Team is always on hand to assist with any enquiries.
      </Box></Typography>
      <Typography variant="h6" color="textPrimary"><Box textAlign="justify">
      &#9400; CupidSpell
      </Box></Typography>
      </Card>
      </div>
    </>
  );
}


export default AboutPage;



import React from 'react';

function NotFoundPage() {
  return (
    <>
      NotFoundPage
    </>
  );
}

export default NotFoundPage;

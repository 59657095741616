export const SEARCH_USERS = 'SEARCH_USERS';
export const UPDATE_SEARCH_LOADING = 'UPDATE_SEARCH_LOADING';
export const UPDATE_SEARCH_RESULTS = 'UPDATE_SEARCH_RESULTS';
export const searchUsersAction = (query) =>{
    
    return  async (dispatch) => {
        dispatch({
            type: UPDATE_SEARCH_LOADING,
            payload : {isLoading : true}
        });
        fetch(`/query?s=${query}`).then((response) =>{                     
            response.json().then(data=>{
              if(data){
                const results = [];
                data.forEach(user => results.push({
                    id: user.id,
                    userName: user.username,
                    pic: user.pic,
                }));
                dispatch({
                    type: UPDATE_SEARCH_RESULTS,
                    payload : {users : results}
                });
              } 
            })
            dispatch({
                type: UPDATE_SEARCH_LOADING,
                payload : {isLoading : false}
            });
          })
          .catch(function() {
            dispatch({
                type: UPDATE_SEARCH_LOADING,
                payload : {isLoading : false}
            });
          });
        }
    }
        
      



























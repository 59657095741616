import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { withRouter } from "react-router";
const defaultPicture = '/default.png';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
  width: 151,
  cursor: 'pointer',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

const UserItem = ({user, history, likeUser, unLikeUser}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
	<CardMedia
        className={classes.cover}
        image={user.pic || defaultPicture}
		onClick={() => history.push(`/user/${user.id}`)}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h6" variant="h6" style={{width:'120px'}}>
            {user.userName}
          </Typography>
        </CardContent>
        <div className={classes.controls}>
          <IconButton aria-label="Like" onClick={()=>user.iLike ? unLikeUser(user.id) : likeUser(user.id)}>
			      <FavoriteBorderIcon color={user.iLike ? "primary":"action"}  />
          </IconButton>
          <IconButton aria-label="Send message" onClick={() => history.push(`/messages/${user.id}`)}>
            <ChatBubbleOutlineIcon />
          </IconButton>
        </div>
        {// eslint-disable-next-line 
        <a href={`/user/${user.id}.html`}></a>
        }
        {// eslint-disable-next-line 
        <a href={`/user/${user.id}`}></a>
        }
      </div>
    </Card>
  );
}

export default withRouter(UserItem);
 const firebaseConfig = {
    apiKey: "AIzaSyBsXZLOlhAfhTMyGqOzTtXmY6NakBGdr_o",
    authDomain: "phoenixstudioadz.firebaseapp.com",
    databaseURL: "https://phoenixstudioadz.firebaseio.com",
    projectId: "phoenixstudioadz",
    storageBucket: "phoenixstudioadz.appspot.com",
    messagingSenderId: "496630396617",
    appId: "1:496630396617:web:b581cc49e5742cfa46ff18"
  };
export default firebaseConfig;


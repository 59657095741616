import React, { useState } from 'react';
import { Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { searchHelpAction } from '../../store/actions/help';
import HelpItem from '../../Components/HelpItem/HelpItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(theme => ({
  root: {
   
    },
  searchBar :{
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
  },
  searchIcon :{
    cursor: 'pointer',
  },
  helpSearchResults: {
    marginTop: '40px',
  }
}));

function HelpPage({ resultsHelp , searchHelp, match, isLoading, isFirstTime, history }) {
 // console.log('results : ', resultsHelp, 'isLoading' , isLoading, 'isFistTime', isFirstTime);
  const [query, setQuery] = useState('');
  let helpId = false;
  if(match && match.params && match.params.id) helpId = match.params.id;
  let helpItem = null;
  if(helpId) {
    helpItem = resultsHelp.find(item => item.id === helpId);
    if(!helpItem) searchHelp(helpId);
  }
  //console.log(helpId,resultsHelp,helpItem);
  const handleSearchUsers = event => {
    if (event.key === 'Enter' && query.length > 3 ){
      history.push('/help');
      searchHelp(query);
    }
    if(event.type === 'click' && query.length > 3 ){
      history.push('/help');
      searchHelp(query);
    }
  }
  const renderItem = (helpItem) => {
    if(helpItem){
      return (
        <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.title} color="textPrimary" gutterBottom>
            {helpItem.title}
          </Typography>
          <Typography className={classes.description} color="textSecondary" gutterBottom>
            {helpItem.description}
          </Typography>
        </CardContent>
      </Card>
              );
    }
    else return null;
  }
  const classes = useStyles();
  return (
    <>
    <CssBaseline />
      <Container maxWidth="sm">
      <Typography variant="h5"> <Box textAlign="left">How can we help you? </Box></Typography>
      <div className={classes.searchBar}>
      <Input autoFocus 
             placeholder="Describe your issue" 
             type="text" 
             value={query}
             style={{flexGrow:1}} 
             onKeyDown = {handleSearchUsers}
             onChange ={(e)=> setQuery(e.target.value)}
             />
      <SearchIcon className={classes.searchIcon} onClick={handleSearchUsers} />
      </div>
      <div className={classes.helpSearchResults}>
      {isLoading ? <CircularProgress /> : null}
      {!helpId && resultsHelp.length > 0 && !isLoading ? 
        resultsHelp.map(item => <HelpItem key={item.id} id={item.id} title={item.title} description={item.description} />)
        :
        null
      }
      {helpId ? renderItem(helpItem) : null}
      {resultsHelp.length === 0 && !isFirstTime && !isLoading ? 'No results match your search criteria': null}
      </div>
      </Container>

    </>
  );
}

const mapStateToProps = state => {
  const { help } = state;
  const {helps, isLoading, isFirstTime } = help;
  return { resultsHelp : helps, isLoading, isFirstTime };
}

const mapDispatchToProps = dispatch => {
  return {
    searchHelp : (query) => dispatch(searchHelpAction(query)),
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(HelpPage));



import { UPDATE_NOTIFICATIONS , MARK_NOTIFICATION_AS_READ, MARK_NOTIFICATION_AS_DELETE } from '../actions/notifications';
const defaultState = {
  userId: '',
  notificationList : [],
  unSeenNotification: 0,
};
const notifications = (state = defaultState, action) => {
switch (action.type) {
    case UPDATE_NOTIFICATIONS:
        if(action.payload.notifications){
            state.unSeenNotification = 0;
            Object.keys(action.payload.notifications).forEach(key =>{ 
                if(!state.notificationList.find(notif=> (notif.id === key && notif.type === action.payload.type))){
                    if(!action.payload.notifications[key].del)
                    state.notificationList.push({
                        id : key,
                        usr : key,
                        type: action.payload.type,
                        time : action.payload.notifications[key].time,
                        seen : action.payload.notifications[key].seen ? true : false,
                        del : action.payload.notifications[key].del ? true : false,
                    });
                }
              });
            state.notificationList.forEach(el=>{
                if(!el.seen && !el.del) state.unSeenNotification++;     
            })   
            return Object.assign({}, state);
        }
        return state;
    case MARK_NOTIFICATION_AS_DELETE:
            const notifdeleteIndex = state.notificationList.findIndex(el => el.id === action.payload.notification.id && el.type === action.payload.notification.type );
            if(notifdeleteIndex !== -1){
            state.notificationList.splice(notifdeleteIndex,1);
            return Object.assign({}, state);
            }
            return state;    
    case MARK_NOTIFICATION_AS_READ:
            const notifseen = state.notificationList.find(el => el.id === action.payload.notification.id && el.type === action.payload.notification.type );
            if(notifseen){
                notifseen.seen = true;
                state.unSeenNotification = 0;
                state.notificationList.forEach(el=>{
                if(!el.seen && !el.del) state.unSeenNotification++;     
            })   
            return Object.assign({}, state);
        }
        return state;    
    default:
    return state;
}
};

export default notifications;

import React, {useState} from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Input } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import {  updateUserNameAction,
		  updateUserSexAction,
		  updateUserAgeAction,
		  updateUserOrientationAction,
		  updateAboutMeAction,
		  reSendEmailConfirmationAction,
		  updateUserProfilePictureAction,
		} from '../../store/actions/user';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import RefreshIcon from '@material-ui/icons/Refresh';
const defaultPicture = '/default.png';

const useStyles = makeStyles(theme => ({
	formControl: {
	  margin: theme.spacing(1),
	  minWidth: 120,
	  
	},
	selectEmpty: {
	  marginTop: theme.spacing(2),
	},
	large: {
		width: theme.spacing(20),
		height: theme.spacing(20),
	  },
	  resendConfirmation : {
		  display: 'flex',
	  },
	  resendConfirmationIcon :{
		cursor : 'pointer',
		margin: '0 5px',
	  },
	  container :{
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',	
		  },
	  },
	  leftDiv : {
		  margin : 10,
		  padding : 10,
	  },
	  rightDiv :{
		margin : 10,
		padding : 10,
	},
  }));

const MyProfile = ({ user, updateUserName, updateUserProfilePicture, updateUserSex, updateUserAge, updateUserOrientation, updateAboutMe, reSendEmailConfirmation }) => {
	const classes = useStyles();
	const [displayName, setDisplayName] = useState('');
	const [emailNotConfirmedMessage, setEmailNotConfirmedMessage] = useState('Email not confirmed, resend confirmation Email'); 
	const [emailConfirmationIcon, setEmailConfirmationIcon] = useState(true);
	const [editDisplayName, setEditDispalyName] = useState(false);
	const [aboutMe, setAboutMe] = useState('');
	const [editAboutMe, setEditAboutMe] = useState(false);
	const handleEmailConfirmationRequest = () => {
		setEmailConfirmationIcon(false);
		setEmailNotConfirmedMessage(`We sent you an email at ${user.email}, please check it and click the confirmation link`);
		reSendEmailConfirmation();
	}
	const HandleUpdateUserProfilePicture = (pic)=>{
		updateUserProfilePicture(pic, user);
	}

    return (
    <>
	<CssBaseline />
      <Container className={classes.container}>
	  <Card className={classes.leftDiv}>
	{(user && user.emailVerified === false) ? 
			<div className={classes.resendConfirmation}>
				<Typography style={{color: 'red'}}>{emailNotConfirmedMessage}</Typography> 
				{emailConfirmationIcon ? <RefreshIcon className={classes.resendConfirmationIcon} onClick={handleEmailConfirmationRequest}/>:null}
				</div> 
				: 
				null}	  
		<ProfilePicture src={user.pic || defaultPicture } userId={user.uid} updateUserProfilePicture={HandleUpdateUserProfilePicture} />
		<br/>
		<Typography>{user.like} Like your profile</Typography> 
		<br/>
		<FormControl className={classes.formControl}>
		<div>
        <InputLabel>Name</InputLabel>
		<Input  value={editDisplayName ? displayName : user.displayName || '' } disabled={!editDisplayName} onChange={event => setDisplayName(event.target.value)}/>
		{editDisplayName ? <>
							 <CancelIcon style={{cursor: 'pointer' }} onClick={() => setEditDispalyName(false)} />
							 <SaveIcon style={{cursor: 'pointer' }} onClick={() => {updateUserName(displayName, user);setEditDispalyName(false);}} />
						  </> : 
							 <EditIcon style={{cursor: 'pointer' }} onClick={() => {setEditDispalyName(true); setDisplayName(user.displayName)}} />}
		</div>
		</FormControl>
		<br/>							 
		<FormControl className={classes.formControl}>
        <InputLabel>Sex</InputLabel>
		<Select
          value={user.sex || 'o'}
          onChange={(event)=> updateUserSex(event.target.value, user)}
        >
          <MenuItem value={'m'}>Male</MenuItem>
          <MenuItem value={'f'}>Female</MenuItem>
          <MenuItem value={'o'}>Other</MenuItem>
        </Select>
		</FormControl>
		</Card>
		<Card className={classes.rightDiv}>
		<FormControl className={classes.formControl}>
        <InputLabel>Age</InputLabel>
		<Select
          value={user.age || 18}
          onChange={(event)=> updateUserAge(event.target.value, user)}
        >
          <MenuItem value={'1'}>18-24</MenuItem>
          <MenuItem value={'2'}>25-30</MenuItem>
          <MenuItem value={'3'}>31-40</MenuItem>
		  <MenuItem value={'4'}>41-50</MenuItem>
		  <MenuItem value={'5'}>51-60</MenuItem>
		  <MenuItem value={'6'}>+61</MenuItem>
        </Select>
		</FormControl>
		<br/>
		<FormControl className={classes.formControl}>
        <InputLabel>Orientation</InputLabel>
		<Select
          value={user.ori || 'o'}
          onChange={(event)=> updateUserOrientation(event.target.value, user)}
        >
          <MenuItem value={'h'}>Hetero</MenuItem>
          <MenuItem value={'g'}>Gay</MenuItem>
          <MenuItem value={'b'}>Bi</MenuItem>
		  <MenuItem value={'o'}>Other</MenuItem>
        </Select>
		</FormControl>
		<br/>
		<FormControl className={classes.formControl}>
		<div>
        <InputLabel>About me</InputLabel>
		<Input  multiline={true} rows={5} style={{minWidth:400}} value={editAboutMe ? aboutMe : user.abt || '' } disabled={!editAboutMe} onChange={event => setAboutMe(event.target.value)}/>
		{editAboutMe ? <>
							 <CancelIcon style={{cursor: 'pointer' }} onClick={() => setEditAboutMe(false)} />
							 <SaveIcon style={{cursor: 'pointer' }} onClick={() => {updateAboutMe(aboutMe, user);setEditAboutMe(false);}} />
						  </> : 
							 <EditIcon style={{cursor: 'pointer' }} onClick={() => {setEditAboutMe(true); setAboutMe(user.abt)}} />}
		</div>
		</FormControl>
		</Card>	
	  </Container>
    </>
	);
}

const mapStateToProps = state => {
  const { user  } = state
  return { user };
}

const mapDispatchToProps = dispatch => {
  return {
	updateUserName: (displayName, user) => dispatch(updateUserNameAction(displayName, user)),
	updateUserProfilePicture: (profilePic, user) => dispatch(updateUserProfilePictureAction(profilePic, user)),
	updateUserSex: (sex, user) => dispatch(updateUserSexAction(sex, user)),
	updateUserAge: (age, user) => dispatch(updateUserAgeAction(age, user)),
	updateUserOrientation: (orientation, user) => dispatch(updateUserOrientationAction(orientation, user)),
	updateAboutMe: (aboutMe, user) => dispatch(updateAboutMeAction(aboutMe, user)),
	reSendEmailConfirmation: () => dispatch(reSendEmailConfirmationAction()),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(MyProfile);

import Firebase from 'firebase';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const updateNotificationsAction = (notifications, type) =>({
	type: UPDATE_NOTIFICATIONS,
	payload : {notifications, type},
  });	

export const MARK_NOTIFICATION_AS_READ =  'MARK_NOTIFICATION_AS_READ';
export const markNotificationAsReadAction = (notification) =>{
	return  async (dispatch) => {
		if(Firebase.auth().currentUser) {
			Firebase.database().ref(`users/${Firebase.auth().currentUser.uid}/${notification.type}s/${notification.id}/seen`).set(true,(error) => {
				if (!error) {
			dispatch({
				type: MARK_NOTIFICATION_AS_READ,
				payload :{notification},
			});
		}});
	} else{
		dispatch({
			type: 'NO_ACTION',
		});
	}
}
}

export const MARK_NOTIFICATION_AS_DELETE =  'MARK_NOTIFICATION_AS_DELETE';
export const markNotificationAsDeleteAction = (notification) =>{
	return  async (dispatch) => {
		if(Firebase.auth().currentUser) {
			Firebase.database().ref(`users/${Firebase.auth().currentUser.uid}/${notification.type}s/${notification.id}/del`).set(true,(error) => {
				if (!error) {
			dispatch({
				type: MARK_NOTIFICATION_AS_DELETE,
				payload :{notification},
			});
		}});
	} else{
		dispatch({
			type: 'NO_ACTION',
		});
	}
}
}
			
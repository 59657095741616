import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import DraftsIcon from '@material-ui/icons/Drafts';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { withRouter } from "react-router";
import utils from '../../utils';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  messageContainer: {
      display: 'flex',
      justifyContent: 'space-between',
  },
  mainContent:{
    display: 'flex',
    alignItems: 'center',
    maxWidth: '70%',
    cursor: 'pointer',
  },
  profilePic: {
      height: 80,
      width: 80,
      backgroundSize: '100% 100%',
      borderRadius: '100%',
      marginRight: 10,
      flexShrink: 0,
  },
  messageUser:{
      overflow:'hidden',
  },
  messageContent: {
    textAlign: 'justify',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexShrink: 1,
  },
  controls:{
    justifySelf: 'center',
    flexShrink: 0,
    display: 'flex',
    flexDirection : 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  }
});

const MessageItem = ({ message, user, history, myId, markMessagesAsRead, markMessagesAsDelete }) => {
  const classes = useStyles();
  let id , profilePic, userName;
  if (user) {
    id = user.id;
    profilePic = user.pic;
    userName = user.userName;
  } else
  {
    id = '';
    profilePic = '/default.png';
    userName = '';
  }
  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.messageContainer}>
        <div className={classes.mainContent} onClick={() => history.push(`/messages/${id}`)}>
        <div className={classes.profilePic} style={{backgroundImage:`url(${profilePic})`}}/>
        <div className={classes.messageUser}>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {userName}
        </Typography>
        <Typography variant="body2" component="p" className={classes.messageContent} style={{fontWeight: (message.seen || message.src===myId) ? 'normal' : 'bold'}}>
          {message.content}
        </Typography>
        </div>
        </div>
        <div className={classes.controls}>
          {utils.converTimeToText(message.time)}
          {message.seen || message.src === myId ? null : <DraftsIcon style={{cursor: 'pointer'}} onClick={()=> markMessagesAsRead(message.conversationId)} />}
          {message.seen ? <DoneAllIcon /> : null}
          <DeleteIcon style={{cursor: 'pointer'}} onClick={()=> markMessagesAsDelete(message.conversationId)} />

        </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default withRouter(MessageItem);
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router";

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    marginTop: 10,
    cursor: 'pointer',
  },
  description :{
    maxHeight: 40,
  }
});

const HelpItem = ({ id , title, description, history }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}  onClick={() => history.push(`/help/${id}`)}>
      <CardContent>
        <Typography className={classes.title} color="textPrimary" gutterBottom>
          {title}
        </Typography>
        <Typography className={classes.description} color="textSecondary" gutterBottom>
          {description}
        </Typography>
        
      </CardContent>
    </Card>
  );
}

export default withRouter(HelpItem);
import React from 'react';
import UserItem from '../UserItem/UserItem';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { likeUserAction, unLikeUserAction } from '../../store/actions/users';
const useStyles = makeStyles(theme => ({
  users: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      height: theme.spacing(20),
    },
  }
}));

const SearchResults = ( {user, likeUser, unLikeUser, resultUsers , isLoading, isFirstTime }) => {
  const handleLikeUser = (profileId)=>{
    likeUser(user.uid, profileId)
  }
  const handleUnLikeUser = (profileId) => {
    unLikeUser(user.uid, profileId)
  }
    
  const searchResults = [...resultUsers];
  const index = searchResults.findIndex(el => el.id === user.uid);
  if(index !== -1) searchResults.splice(index,1);
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
        <Container maxWidth='xl'>
        {!isFirstTime ? <Typography variant="h5"> Results </Typography>: null}
        <div className={classes.users}>
          {isLoading ? <CircularProgress /> : null}
          {searchResults.length > 0 && !isLoading ? searchResults.map(user => <UserItem key={user.id} user={user} likeUser={handleLikeUser} unLikeUser={handleUnLikeUser} />):null}
          {searchResults.length === 0 && !isFirstTime && !isLoading ? 'No results match your search criteria': null}
        </div>
        {!isFirstTime  ? <hr/> : null}
      </Container>
    </>
  );
}

const mapStateToProps = state => {
  const { user, search } = state;
  const {users ,isLoading, isFirstTime } = search;
  return { user, resultUsers : users, isLoading, isFirstTime };
}

const mapDispatchToProps = dispatch => {
  return {
    likeUser: (myId, profileId) => dispatch(likeUserAction(myId, profileId)),
    unLikeUser: (myId, profileId) => dispatch(unLikeUserAction(myId, profileId)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SearchResults);


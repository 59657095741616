export const UPDATE_HELP_LOADING = 'UPDATE_HELP_LOADING';
export const UPDATE_SEARCH_HELPS = 'UPDATE_SEARCH_HELPS';
export const searchHelpAction = (query) =>{
    
    return  async (dispatch) => {
        dispatch({
            type: UPDATE_HELP_LOADING,
            payload : {isLoading : true}
        });
        fetch(`/help?s=${query}`).then((response) =>{            
            response.json().then(data=>{
              if(data){
                const results = [];
                data.forEach(item => results.push({
                    id: item.id,
                    title : item.title,
                    description : item.description,
                }));
                dispatch({
                    type: UPDATE_HELP_LOADING,
                    payload : {helps : results}
                });
              } 
            })
            dispatch({
                type: UPDATE_HELP_LOADING,
                payload : {isLoading : false}
            });
          })
          .catch(function() {
            dispatch({
                type: UPDATE_HELP_LOADING,
                payload : {isLoading : false}
            });
          });
        }
    }
        
      



























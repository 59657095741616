import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import IconButton from '@material-ui/core/IconButton';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import { withRouter } from "react-router";
import Card from '@material-ui/core/Card';
import FavoriteIcon from '@material-ui/icons/Favorite';
const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(30),
    height: theme.spacing(30),
  },
  likes :{
    display : 'flex',
    alignItems: 'center',
    margin: '10px',
  },
  container :{
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',	
		  },
	  },
  leftDiv : {
    margin : 10,
    padding : 30,
    flexShrink: 0,
  },
  rightDiv :{
  margin : 10,
  padding : 40,
  alignItems: 'center',
  display: 'flex',
  color: '#505050',
  textAlign: 'justify'
},
}));

const UserProfile = ({user, history, likeUser, unLikeUser, viewUser})=>{
    const [viewedProfiles, setViewedProfiles] = useState([]);
    const classes = useStyles();
    useEffect(()=>{
      if(user && viewUser && !viewedProfiles.includes(user.id)) {
        viewedProfiles.push(user.id);
        setViewedProfiles([...viewedProfiles]);
        viewUser(user.id);
      }

//      console.log('viewUser lanuched');
    },[user, viewUser, viewedProfiles])
    useEffect(()=>{
      if(user){
        document.querySelectorAll("meta")[1].setAttribute("content",`CupidSpell.com | ${user.userName}, About me :${user.abt}`)
      }
    },[user])
    if(!user) return <p>Loading...</p>
    return (
    <>
      <CssBaseline />
      <Container className={classes.container}>
        <Card className={classes.leftDiv}>
        <Avatar alt={user.userName} src={user.pic} className={classes.large} />
        <IconButton aria-label="Send message" onClick={() => history.push(`/messages/${user.id}`)}>
            <ChatBubbleOutlineIcon />
        </IconButton>
         <IconButton aria-label="Send message" onClick={() =>  user.iLike ? unLikeUser(user.id) : likeUser(user.id)}>
                        <ThumbUpAltOutlinedIcon color={user.iLike ? "primary":"action"}  />
        </IconButton>
        <div className={classes.likes}>
          <Typography  color="primary" >{user.like}</Typography >
          <FavoriteIcon color="primary" style={{marginLeft:'3px'}}/>
          
        </div>
        <Typography variant="h2">{user.userName}</Typography >
        <Typography variant="h5">Age : 
                                      {user.age === '1' ? '18-24' : null}
                                      {user.age === '2' ? '25-30' : null}
                                      {user.age === '3' ? '31-40' : null}
                                      {user.age === '4' ? '41-50' : null}
                                      {user.age === '5' ? '51-60' : null}
                                      {user.age === '6' ? '+61' : null}
        </Typography >
        <Typography variant="h5">Sex : {user.sex === 'f' ? 'Female':null}{user.sex === 'm' ? 'Male':null}{user.sex === 'o' ? 'Other':null}</Typography >
        <Typography variant="h5">{user.ori === 'h' ? 'Hetero':null}{user.ori === 'g' ? 'Gay':null}{user.ori === 'b' ? 'Bi':null}</Typography >
        </Card>
        <Card className={classes.rightDiv}>
        <Typography variant="h5">About me : {user.abt}</Typography >
        </Card>
        
      </Container>
  </>)
    
}

export default withRouter(UserProfile);
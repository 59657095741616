import { SET_USER_ACTION,
		 LOG_OUT_ACTION,
		 LOG_IN_ACTION,
		 REGISTER_ACTION,
		 UPDATE_USERNAME,
		 UPDATE_USER_PIC,
		 UPDATE_USER_SEX,
		 UPDATE_USER_AGE,
		 UPDATE_USER_INFOS,
		 UPDATE_USER_ORIONTATION,
		 UPDATE_USER_PHOTO,
		 UPDATE_ABOUT_ME,
		 REST_PASSWORD_USER,
		 } from '../actions/user';
import Firebase from 'firebase';
const defaultState = {};
const user = (state = defaultState, action) => {
switch (action.type) {
    case SET_USER_ACTION:
		return action.payload;
	case LOG_OUT_ACTION:
		Firebase.auth().signOut();
		return  defaultState;
	case LOG_IN_ACTION:
		return action.payload;
	case REGISTER_ACTION:
		return action.payload;
	case UPDATE_USERNAME:
		state.displayName = action.payload.displayName;
		return Object.assign({},state);
	case UPDATE_USER_PIC:
		state.pic = action.payload.pic;
		return Object.assign({},state);
	case UPDATE_USER_SEX:
		state.sex = action.payload.sex;
		return Object.assign({},state);
	case UPDATE_USER_AGE:
		state.age = action.payload.age;
		return Object.assign({},state);
	case UPDATE_USER_ORIONTATION:
			state.ori = action.payload.ori;
			return Object.assign({},state);
	case UPDATE_USER_PHOTO:
			state.photo = action.payload.photo;
			return Object.assign({},state);
	case UPDATE_ABOUT_ME:
			state.abt = action.payload.abt;
			return Object.assign({},state);
	case UPDATE_USER_INFOS:
		if(action.payload && action.payload.like) action.payload.like = 0;
		if(action.payload && action.payload.like){
		Object.keys(action.payload.likes).forEach(key =>{            
			action.payload.like++;
		 });
		}
		return Object.assign({}, state, action.payload);
	case REST_PASSWORD_USER:
		return action.payload;
    default:
      return state;
  }
};

export default user;

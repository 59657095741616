import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import logo from '../../assets/images/logo.svg';

const useStyles = makeStyles(theme => ({
  root: {
   
    },
  container :{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height : 'calc(100vh - 100px)'
  },
  card :{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding : 20,
  }
}));

function ContactPage() {
 
  const classes = useStyles();
  return (
    <>
    <CssBaseline />
      <div className={classes.container} >
      <Card className={classes.card}>
      <Typography variant="h2"><Box textAlign="center">CupidSpell</Box></Typography>    
      <img alt="logo" src={logo} width="100" height="100" />
      <Typography variant="h4"><Box textAlign="center">Contact Us</Box></Typography>
      <Typography variant="h5"><Box textAlign="center">We’re all ears.</Box></Typography>
      <Typography variant="h5"><Box textAlign="center">For tech support : phoenixstudiodz@gmail.com </Box></Typography>
      <Typography variant="h5"><Box textAlign="center">For help please check the help page or send us an email </Box></Typography>
      </Card>
      </div>
    </>
  );
}


export default ContactPage;



import React from 'react';
import MyProfile from '../../Components/MyProfile/MyProfile';
function ProfilePage() {
  return (
    <>
      <MyProfile />
    </>
  );
}

export default ProfilePage;

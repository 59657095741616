import React from 'react';
import {connect} from 'react-redux';
import { likeUserAction, unLikeUserAction, viewUserAction, getUserFromDBAction } from '../../store/actions/users';
import UserProfile from '../../Components/UserProfile/UserProfile';
function UserProfilePage({ match, users, user, likeUser, unLikeUser, viewUser, getUserFromDB }) {
  if(!match) return <p>Page Not found</p>
  let idFromUrl = match.params.id;
  if(match.params.id.includes('.html') && idFromUrl) idFromUrl = idFromUrl.replace('.html','');
  const userProfile = users.find(user => user.id === idFromUrl);
  //if user does not existe add redux call to add it from the store
  if(!userProfile && idFromUrl) {
    //console.log('need to find the user here');
    if(typeof stringValue){
      getUserFromDB(idFromUrl.replace(/#|\.|\$|\[|]/g,''));
    }
  }
  const handleLikeUser = (profileId) => {
    likeUser(user.uid, profileId);
  };
  const handleUnLikeUser = (profileId) => {
    unLikeUser(user.uid, profileId);
  };
  const handleViewUser = (profileId) =>{
    viewUser(user.uid, profileId);
  };
  return (
    <>
      {!userProfile  && idFromUrl ? <p>Loading...</p> : null}
      {userProfile && userProfile.userName ? <UserProfile user={userProfile} likeUser={handleLikeUser}  unLikeUser={handleUnLikeUser} viewUser={handleViewUser} />: null}
      {userProfile && !userProfile.userName ? <p> Sorry User Does not exist, Or account deleted!</p> : null}

    </>
  );
}
const mapStateToProps = state => {
  const { users , user} = state;
  return { users , user};
}

const mapDispatchToProps = dispatch => {
  return {
    likeUser: (myId, profileId) => dispatch(likeUserAction(myId, profileId)),
    unLikeUser: (myId, profileId) => dispatch(unLikeUserAction(myId, profileId)),
    viewUser: (myId, profileId) => dispatch(viewUserAction(myId, profileId)),
    getUserFromDB : (profileId) => dispatch(getUserFromDBAction(profileId)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(UserProfilePage);


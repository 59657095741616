import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import CoversationList from '../../Components/CoversationList/CoversationList';
import { loadCoversationAction, sendMessageAction, markMessagesAsReadAction } from '../../store/actions/messages';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Input from '@material-ui/core/Input';
import SendIcon from '@material-ui/icons/Send';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router";
const defaultPicture = '/default.png';
const useStyles = makeStyles(theme => ({
	small: {
		width: theme.spacing(10),
    height: theme.spacing(10),
    cursor : 'pointer',
    },
    sendMessage : {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '40px',
    },
    sendMessageInput : {
      flexGrow: '1',
    },
    conversationList : {
      height: 'calc(100vh - 250px)',
      minHeight: '100px',
      overflow: 'hidden',
      overflowY: 'auto',
      borderRadius: '5px',
    },
    profile :  {
      backgroundColor: '#3f51b522',
      display: 'flex',
      margin : '5px 0',
      padding : '3px 10px',
      alignItems: 'center',
      borderRadius: '15px 15px 5px 5px',
    }
  }));

function ConversationPage({ match, user, users, messages, loadCoversation, sendMessage, history, markMessagesAsRead, windowFocused }) {
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const [conversationListRef, setConversationListRef] = useState(null);
  const correspondant = users.find(el => el.id === match.params.id)
  useEffect(()=>{
    if(conversationListRef) conversationListRef.scrollTop = conversationListRef.scrollHeight;
   })
  
  useEffect(()=>{
    if(correspondant && user.uid){
      const convId = correspondant.id > user.uid ? `${correspondant.id}-${user.uid}` : `${user.uid}-${correspondant.id}`;
      const conv = messages.conversations.find(conv => conv.id === convId);
      if(conv){
      const msg = conv.messageList.find(el => el.dst === user.uid && el.seen === false);
      if(msg && windowFocused){
        markMessagesAsRead([conv]);
        }
      }
    }
  },[messages, windowFocused, correspondant,markMessagesAsRead, user.uid]);
  if(!correspondant && !user.uid) return <p>Loading...</p>
  if(correspondant && !user.uid) return <p>You need to login</p>
  const coversationID = correspondant.id > user.uid ? `${correspondant.id}-${user.uid}` : `${user.uid}-${correspondant.id}`;
  const conversationlistener = messages.conversationids.find(el => el === coversationID );
  if(!conversationlistener) loadCoversation(coversationID);
  const conversation = messages.conversations.find(el => el.id === coversationID );
  const handleSendMessage = () => {
    if(message) sendMessage(coversationID,message,user.uid, correspondant.id);
    setMessage('');
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && message) {
    sendMessage(coversationID,message,user.uid, correspondant.id);
    setMessage('');
    }
  } 

  

  return (
    <>
     <CssBaseline />
      <Container fixed>
        <div className={classes.profile}>
        <Avatar alt={correspondant.userName} src={correspondant.pic || defaultPicture } className={classes.small} onClick={() => history.push(`/user/${correspondant.id}`)} />
        <Typography style={{margin:'10px'}}>{correspondant.userName}</Typography>
        </div>
        <div ref={(ref)=>setConversationListRef(ref)} className={classes.conversationList}>
        <CoversationList conversation={conversation} user={user} correspondant={correspondant} />
        </div>
        <br/>
        <div className={classes.sendMessage}>
        <Input className={classes.sendMessageInput}placeholder='Type a message' value={message} onKeyDown={(event)=> handleKeyDown(event)} onChange={(event)=> setMessage(event.target.value)} /><SendIcon className={classes.sendMessageIcon} onClick={()=> handleSendMessage()} />
        </div>
      </Container>
      
    </>
  );
}

const mapStateToProps = state => {
  const { users, user, messages } = state;
  return { users, user, messages};
}

const mapDispatchToProps = dispatch => {
  return {
    loadCoversation: (coversationID) => dispatch(loadCoversationAction(coversationID)),
    sendMessage: (coversationID, content , src, dst) => dispatch(sendMessageAction(coversationID, content, src, dst)),
    markMessagesAsRead: (conversations) => dispatch(markMessagesAsReadAction(conversations)),
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ConversationPage));

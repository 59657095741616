const converTimeToText = (epochTimeStamp) => {
    const now = new Date();
    if((now.getTime() - epochTimeStamp) < 10000) return 'now';
    const date = new Date(epochTimeStamp);
    if(now.getDate() === date.getDate() &&  now.getMonth() === date.getMonth()) return `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
    return `${date.getDate() < 10 ? '0'+date.getDate() : date.getDate()}/${date.getMonth() < 9 ? '0' + (date.getMonth()+1) : (date.getMonth()+1)} ${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
}

const utils = {
    converTimeToText,
}
export default utils;
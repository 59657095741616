import Firebase from 'firebase';
export const LOAD_COVERSATION = 'LOAD_COVERSATION';
export const loadCoversationAction = (conversationId) =>({
	type: LOAD_COVERSATION,
	payload : conversationId,
  });

export const LOAD_COVERSATIONS = 'LOAD_COVERSATIONS';	
export const loadCoversationsActions = (conversationIds) =>({
	type: LOAD_COVERSATIONS,
	payload : conversationIds,
});	

export const SET_USER_ID = 'SET_USER_ID';
export const setUserIdAction = (userId) => ({
	type: SET_USER_ID,
	payload : userId,
});	

export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION';
export const updateCoversationAction = (conversationId, messages) => ({
    type: UPDATE_CONVERSATION,
	payload : {conversationId, messages},
});

export const SEND_MESSAGE = 'SEND_MESSAGE';
export const sendMessageAction = (coversationID, content, src, dst) => 
{
		return  async (dispatch) => {
        const newKey = Firebase.database().ref(`/messages/${coversationID}/`).push().key;
        let updates = {};
        const newMessage =  { 
            id : newKey,
            src,
            dst,
            type: 1,//text
            content,
            seen: false,
            time: Firebase.database.ServerValue.TIMESTAMP,
        }
        updates[`/messages/${coversationID}/${newKey}`] = newMessage;
        updates[`/users/${src}/conversations/${coversationID}`] = Firebase.database.ServerValue.TIMESTAMP;
        updates[`/users/${dst}/conversations/${coversationID}`] = Firebase.database.ServerValue.TIMESTAMP;
		await Firebase.database().ref().update(updates).then(() =>{
            dispatch({
                 	type: SEND_MESSAGE,
                 	payload :{coversationID,  message : newMessage},
                 })
        }            
        )
    }
    }

export const MARK_MESSAGES_AS_READ =  'MARK_MESSAGES_AS_READ';
export const markMessagesAsReadAction = (conversations) => 
{
    const myId = Firebase.auth().currentUser.uid;
	return  async (dispatch) => {
        if(conversations){
        let updates = {};
        conversations.forEach((conv)=>{
            conv.messageList.forEach((msg)=>{
                if(!msg.seen && msg.dst === myId) updates[`/messages/${conv.id}/${msg.id}/seen`] = true;
            });
        });
        await Firebase.database().ref().update(updates).then(() =>{
            dispatch({
                type: MARK_MESSAGES_AS_READ,
                 payload :{conversations},
           })
         });
        } else {
        dispatch({
			type: 'NO_ACTION',
		});
}
}
}

export const MARK_MESSAGES_AS_DELETE =  'MARK_MESSAGES_AS_DELETE';
export const markMessagesAsDeleteAction = (conversations) => 
{
	return  async (dispatch) => {
        if(conversations){
        let updates = {};
        conversations.forEach((conv)=>{
            conv.messageList.forEach((msg)=>{
                if(!msg.del) updates[`/messages/${conv.id}/${msg.id}/del`] = true;
            });
        });
        await Firebase.database().ref().update(updates).then(() =>{
            dispatch({
                type: MARK_MESSAGES_AS_DELETE,
                 payload :{conversations},
           })
        });
        } else {
        dispatch({
			type: 'NO_ACTION',
		});
}
}
}



import React from 'react';
import MessageItem from '../MessageItem/MessageItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection : 'column',
    '& > *': {
      margin: theme.spacing(1),
      width: '95%',
      height: theme.spacing(16),
    },
  },
}));

const MessageList = ({ messageList, users, myId, markMessagesAsRead, markMessagesAsDelete }) => {
  const classes = useStyles();
  const findUser = (message) => {
    if(message.dst === myId) return users.find(user => user.id === message.src);
    if(message.src === myId) return users.find(user => user.id === message.dst);
  }
  if(!myId) return <p>Loading ...</p>;
  //need to sort the messages
  const sortedMessageList =  [...messageList];
  sortedMessageList.sort((a,b)=>a.time < b.time);
  return (
  <div className={classes.root}>
      {sortedMessageList && sortedMessageList.map(message => <MessageItem key={message.id} message={message} user={findUser(message)} myId={myId} markMessagesAsRead={markMessagesAsRead} markMessagesAsDelete={markMessagesAsDelete} />)}
      {(!sortedMessageList || sortedMessageList.length===0) ? <p>you have no messages</p>: null}
  </div>
  );
}
export default MessageList;

import React from 'react';

function AccountPage() {
  return (
    <>
      AccountPage
    </>
  );
}

export default AccountPage;

import { LOAD_COVERSATION,
         LOAD_COVERSATIONS,
         SEND_MESSAGE,
         UPDATE_CONVERSATION,
         SET_USER_ID,
         MARK_MESSAGES_AS_READ,
         MARK_MESSAGES_AS_DELETE 
        } from '../actions/messages';
const defaultState = {
  userId: '',
  lastMessage : {},
  messageList : [],
  unseenMessages: 0,
  conversationids :[
  ],
  conversations : [

  ]
  }
const messages = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER_ID:
      state.userId = action.payload;
      return Object.assign({},state);
    case LOAD_COVERSATION:
      state.conversationids.push(action.payload);
      state.conversations.push({
          id: action.payload,
          messageList: [],
        });
      return Object.assign({},state);
    case LOAD_COVERSATIONS:
      if(!action.payload) return state;
        state.conversationids.splice(0, state.conversationids.length);
        Object.keys(action.payload).forEach(key =>{            
          state.conversationids.push(key);
        });
      return Object.assign({},state);
    case UPDATE_CONVERSATION:
      const idInConversationids = state.conversationids.find(el => el === action.payload.conversationId);
      if(!idInConversationids) state.conversationids.push(idInConversationids);//this can't happens but just in case 
      let conversations = state.conversations.find(el => el.id === action.payload.conversationId);
      if(!conversations){
        state.conversations.push({
          id: action.payload.conversationId,
          messageList: [],
        });
       conversations = state.conversations.find(el => el.id === action.payload.conversationId);
      }
      //now we transforme data into an array and push items to item list 
      conversations.messageList.splice(0, conversations.messageList.length)
      if(action.payload.messages){
        Object.keys(action.payload.messages).forEach(key =>{            
          if(!action.payload.messages[key].del) conversations.messageList.push(action.payload.messages[key]);
        });
      }
      const lastMessage = conversations.messageList[conversations.messageList.length-1];
      if(!lastMessage) return state;
      lastMessage.conversationId = action.payload.conversationId;
      state.lastMessage = lastMessage;
      //find if that conversation already exist :
      const indexofConv = state.messageList.findIndex(el => el.conversationId === action.payload.conversationId);
      if(indexofConv !== -1) state.messageList.splice(indexofConv,1);
      state.messageList.unshift(lastMessage);
      state.unseenMessages = 0;
      state.messageList.forEach((msg)=>{
        if(msg.dst === state.userId && !msg.seen) state.unseenMessages++;
      })
      return Object.assign({},state);
    case SEND_MESSAGE:
      
    
        return state;

    case MARK_MESSAGES_AS_READ:
      action.payload.conversations.forEach((conv)=>{
        conv.messageList.forEach((msg)=>{
           if(!msg.seen && msg.dst === state.userId) msg.seen = true;
        });
      });
    return Object.assign({}, state);
    case MARK_MESSAGES_AS_DELETE:
      action.payload.conversations.forEach((conv)=>{
      const convToDelete = state.conversations.find(el => el.id === conv.id);
      convToDelete.messageList.splice(0,convToDelete.messageList.length);
      const indexofConv = state.messageList.findIndex(el => el.conversationId === conv.id);
      if(indexofConv !== -1) state.messageList.splice(indexofConv,1);
      });
    state.unseenMessages = 0;
    state.messageList.forEach((msg)=>{
      if(msg.dst === state.userId && !msg.seen) state.unseenMessages++;
    })
    return Object.assign({}, state);    
    default:
    return state;
}
};

export default messages;

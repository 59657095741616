import Firebase from 'firebase';
import { UPDATE_USERS, UPDATE_USER, LIKE_USER, UNLIKE_USER } from '../actions/users';
const defaultState = [ ];
const users = (state = defaultState, action) => {
switch (action.type) {
    case UPDATE_USER:
        if(action.payload && action.payload.username){
            if(state.find(user => user.id === action.payload.id)) return state;
             const userToAdd = {
                id: action.payload.id,
                pic : action.payload.pic,
                userName : action.payload.username,
                age : action.payload.age,
                ori : action.payload.ori,
                sex : action.payload.sex,
                abt : action.payload.abt,
                like : 0,
                iLike : false,
                }
             if(action.payload.likes) {
                Object.keys(action.payload.likes).forEach(item =>{            
                     userToAdd.like++;
                    if(Firebase.auth().currentUser && item === Firebase.auth().currentUser.uid) userToAdd.iLike= true;   
                    });
            } 
            state.push(userToAdd);       
            return [...state];
        }
    return state;       
    case UPDATE_USERS:
        if(!action.payload) return state;
        Object.keys(action.payload).forEach(key =>{            
            if(!state.find(user=> user.id === key) && action.payload[key].username){
                action.payload[key].like = 0;
                action.payload[key].iLike = false;
		        if(action.payload[key].likes){
		        Object.keys(action.payload[key].likes).forEach(item =>{            
                action.payload[key].like++;
                if(Firebase.auth().currentUser && item === Firebase.auth().currentUser.uid) action.payload[key].iLike= true;   
		        });
		        }
            state.push({id: key,
                        pic : action.payload[key].pic,
                        userName : action.payload[key].username,
                        age : action.payload[key].age,
                        ori : action.payload[key].ori,
                        sex : action.payload[key].sex,
                        abt : action.payload[key].abt,
                        like : action.payload[key].like,
                        iLike : action.payload[key].iLike,
                     });
            }
        })        
    return [...state];
    case LIKE_USER:
        const userToLike = state.find(user => user.id === action.payload.profileId);
        if(userToLike) {
            userToLike.iLike = true;
            userToLike.like++;
        }
        return [...state];
    case UNLIKE_USER:
        const userToUnLike = state.find(user => user.id === action.payload.profileId);
        if(userToUnLike) {
            userToUnLike.iLike = false;
            if(userToUnLike.like > 0) userToUnLike.like--;
        }
        return [...state];
    default:
    return state;
}
};

export default users;

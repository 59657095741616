import React from 'react';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import MessageList from '../../Components/MessageList/MessageList';
import { markMessagesAsReadAction, markMessagesAsDeleteAction } from '../../store/actions/messages';

function MessagesPage({ messages, users, user, markMessagesAsRead, markMessagesAsDelete }) {
  const handleMarkAllAsRead = () => {
    markMessagesAsRead(messages.conversations);
  }
  const handleMarkAllAsDelete = () => {
    markMessagesAsDelete(messages.conversations);
  }
  const handleMarkMessageAsRead = (conversationId) => {
      const conv = messages.conversations.find(conv => conv.id === conversationId);
      markMessagesAsRead([conv]);
  }
  const handleMarkMessageAsDelete = (conversationId) => {
     // console.log(conversationId);
      const conv = messages.conversations.find(conv => conv.id === conversationId);
      markMessagesAsDelete([conv]);
  }
  return (
    <>
       <Button color="primary" onClick={handleMarkAllAsRead}>Mark all as read</Button>
       <Button color="primary" onClick={handleMarkAllAsDelete}>Delete all messages</Button>
       <MessageList messageList={messages.messageList} users={users} myId={user.uid} markMessagesAsRead={handleMarkMessageAsRead} markMessagesAsDelete={handleMarkMessageAsDelete} />
    </>
  );
}

const mapStateToProps = state => {
  const { messages, users, user } = state
  return { messages, users, user };
}

const mapDispatchToProps = dispatch => {
  return {
    markMessagesAsRead: (conversations) => dispatch(markMessagesAsReadAction(conversations)),
    markMessagesAsDelete: (conversations) => dispatch(markMessagesAsDeleteAction(conversations)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(MessagesPage);

import React, { useEffect } from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { setUserAction } from '../../store/actions/user';
import { setUserIdAction } from '../../store/actions/messages';
import PrimarySearchAppBar from './PrimarySearchAppBar';
import NavBarGuest from './NavBarGuest';
import Firebase from 'firebase';
const Navigation = ({openDrawer, history , user, setUser, setUserId}) => {
	  if(history.location.pathname === '/login' && user.email) history.push('/');
	  if(history.location.pathname === '/register' && user.email) history.push('/');
  useEffect(()=>{
    Firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setUserId(user.uid);
		if(history.location.pathname === '/login' || history.location.pathname === 'register') history.push('/');
      } 
	  
    })
  },[history, setUser, setUserId]);

    return (
      <>
        {user.email ? <PrimarySearchAppBar openDrawer={openDrawer}/> : <NavBarGuest openDrawer={openDrawer}/>}
      </>
      );
    }
const mapStateToProps = state => {
  const { user  } = state
  return { user };
}

const mapDispatchToProps = dispatch => {
  return {
    setUser: (user) => dispatch(setUserAction(user)),
    setUserId: (userId)=> dispatch(setUserIdAction(userId)),

  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Navigation));


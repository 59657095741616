import React from 'react';
import NotificationItem from '../NotificationItem/NotificationItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection : 'column',
    '& > *': {
      margin: theme.spacing(1),
      width: '95%',
      height: theme.spacing(16),
    },
  },
}));

const NotificationList = ({ notificationList, users, myId, markNotificationAsRead, markNotificationAsDelete }) => {
  const classes = useStyles();
  const findUser = (usr) => {
     return users.find(user => user.id === usr);
  }
  if(!myId) return <p>Loading ...</p>;
  //need to sort the messages
  const sortedNotificationList =  [...notificationList];
  sortedNotificationList.sort((a,b)=>a.time < b.time);
  return (
  <div className={classes.root}>
      {sortedNotificationList && sortedNotificationList.map(notification => <NotificationItem key={`${notification.id}-${notification.type}`} notification={notification} user={findUser(notification.usr)} myId={myId} markNotificationAsRead={markNotificationAsRead} markNotificationAsDelete={markNotificationAsDelete} />)}
      {(!sortedNotificationList || sortedNotificationList.length===0) ? <p>you have no notification</p>: null}
  </div>
  );
}
export default NotificationList;

import { UPDATE_HELP_LOADING, UPDATE_SEARCH_HELPS } from '../actions/help';
const defaultState ={
     helps : [ ],
     isLoading  : false,
     isFirstTime : true,

};
const search = (state = defaultState, action) => {
switch (action.type) {
    case UPDATE_HELP_LOADING:
        if(action.payload && action.payload.isLoading) state.isFirstTime = false;
        return Object.assign({}, state, action.payload);
    case UPDATE_SEARCH_HELPS:
        return Object.assign({}, state, action.payload);
    default:
        return state;
}
};

export default search;

import React, { useState, useEffect } from 'react';
import HomePage from './Pages/HomePage/HomePage';
import ContactPage from './Pages/ContactPage/ContactPage';
import AboutPage from './Pages/AboutPage/AboutPage';
import LoginPage from './Pages/LoginPage/LoginPage';
import RegisterPage from './Pages/RegisterPage/RegisterPage';
import RestPasswordPage from './Pages/RestPasswordPage/RestPasswordPage';
import HelpPage from './Pages/HelpPage/HelpPage';
import MessagesPage from './Pages/MessagesPage/MessagesPage';
import ProfilePage from './Pages/ProfilePage/ProfilePage';
import UserProfilePage from './Pages/UserProfilePage/UserProfilePage';
import AllUsersPage from './Pages/AllUsersPage/AllUsersPage';
import AccountPage from './Pages/AccountPage/AccountPage';
import NotificationsPage from './Pages/NotificationsPage/NotificationsPage';
import InvitationsPage from './Pages/InvitationsPage/InvitationsPage';
import ConversationPage from './Pages/ConversationPage/ConversationPage';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';
import Navigation from './Components/Navigation/Navigation';
import NavigationDrawer from './Components/Navigation/NavigationDrawer';
import NavigationDrawerGuest from './Components/Navigation/NavigationDrawerGuest';
import { updateUserInfosAction } from './store/actions/user';
import { updateUsersAction } from './store/actions/users';
import { updateCoversationAction, loadCoversationsActions } from './store/actions/messages';
import { updateNotificationsAction } from './store/actions/notifications';
import notificationSound from './assets/audio/agile.mp3';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Firebase from 'firebase';
import { InitAppFromCache } from './InitApp';
import './App.css';

function App({ user, updateUserInfos, updateUsers, updateCoversation, loadCoversations, messages, updateNotifications }) {
  const [notifLastPlayedId,setNotifLastPlayedId] = useState(null);
  const [titleRef, setTitleRef] = useState(null);
  const [timerTitle, setTimerTitle] = useState(null);
  const [audio, setAudio]  = useState(null);
  const [conversationIdslistener, setConversationIdslistener] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [windowFocused, setWindowFocused] = useState(true);
  const handleCloseDrawer = () => setShowDrawer(false);
  const handleOpenDrawer = () => setShowDrawer(true);  
  const notifyTitle = ()=>{
    if(titleRef.innerHTML === 'CUPID SPELL | ONLINE DATING') titleRef.innerHTML = 'You have new message';
    else titleRef.innerHTML = 'CUPID SPELL | ONLINE DATING'
    if(windowFocused){
      titleRef.innerHTML = 'CUPID SPELL | ONLINE DATING'
      clearTimeout(timerTitle);
      setTimeout(null);
    }else{
      if(!timerTitle){
        setTimerTitle(
          setTimeout(()=>{
           if(!windowFocused) notifyTitle();
          },1000)
        )
      }
    }    
  }
  
  if(messages && messages.lastMessage && messages.lastMessage.dst === user.uid && user.uid && !messages.lastMessage.seen){
    if((new Date()).getTime() - messages.lastMessage.time < 5000){
      if(notifLastPlayedId !== messages.lastMessage.id){
        setNotifLastPlayedId(messages.lastMessage.id);
        if(!windowFocused){
          if(audio.paused) {
            audio.play();
            notifyTitle();
          }
        }
      }
    }
  }
  useEffect(()=>{
    if(updateUsers) InitAppFromCache(updateUsers);
  },[updateUsers])
  useEffect(()=>{
    if(titleRef && windowFocused) titleRef.innerHTML = 'CUPID SPELL | ONLINE DATING'
    if(timerTitle && windowFocused) {
      clearTimeout(timerTitle);  
      setTimerTitle(null);
    }
  },[windowFocused, titleRef, timerTitle])

  useEffect(()=>{
    window.onfocus = ()=>{
    setWindowFocused(true);
    }
    window.onblur = ()=>{
      setWindowFocused(false);
      }
    setAudio(new Audio(notificationSound));
    setTitleRef(document.getElementsByTagName('title')[0]);
  },[])

  useEffect(()=>{
    if(user.uid){
      let ref = Firebase.database().ref(`/users/${user.uid}/`);
      ref.on('value', snapshot => {
       const userInfos = snapshot.val();
       updateUserInfos(userInfos);
       });
    }
    },[user.uid, updateUserInfos]);
   useEffect(()=>{
        let ref = Firebase.database().ref(`/users/`);
         ref.on('value', snapshot => {
         const users = snapshot.val();
           updateUsers(users);
           });
   },[updateUsers]);
  useEffect(()=>{
    if(user.uid){
      let ref = Firebase.database().ref(`/users/${user.uid}/conversations/`);
      ref.on('value', snapshot => {
       const conversations = snapshot.val();
       loadCoversations(conversations);
       });
    }
    },[user.uid, loadCoversations]);

  useEffect(()=>{
    if(user.uid){
    let ref = Firebase.database().ref(`/users/${user.uid}/likes/`);
    ref.on('value', snapshot => {
    const notifications = snapshot.val();
    updateNotifications(notifications, 'like');
    });
    }
   },[user.uid, updateNotifications]);
   useEffect(()=>{
    if(user.uid){
    let ref = Firebase.database().ref(`/users/${user.uid}/views/`);
    ref.on('value', snapshot => {
    const notifications = snapshot.val();
    updateNotifications(notifications, 'view');
    });
    }
   },[user.uid, updateNotifications]);
  
  useEffect(()=>{
    if(messages && messages.conversationids){    
    messages.conversationids.forEach(conversationId => {
      if(!conversationIdslistener.find(el => el === conversationId)){    
        conversationIdslistener.push(conversationId)
        setConversationIdslistener([...conversationIdslistener]);
        let ref = Firebase.database().ref(`/messages/${conversationId}/`);
        ref.on('value', snapshot => {
         const newMessages = snapshot.val();
         updateCoversation(conversationId, newMessages);
         });
        }
    });
  }
});
  
  return (
    <>
      <Router>
        <Navigation openDrawer={handleOpenDrawer} />
		{user.email ? <NavigationDrawer showDrawer={showDrawer} closeDrawer={handleCloseDrawer}/> : <NavigationDrawerGuest showDrawer={showDrawer} closeDrawer={handleCloseDrawer}/>}
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/about">
            <AboutPage />
          </Route>
          <Route exact path="/contactus">
            <ContactPage />
          </Route>
          <Route exact path="/login">
            <LoginPage />
          </Route>
         <Route exact path="/register">
            <RegisterPage />
          </Route>
          <Route exact path="/rest-password">
            <RestPasswordPage />
          </Route>

        <Route exact path="/notifications">
           <NotificationsPage />
         </Route>
		 <Route exact path="/messages">
           <MessagesPage />
         </Route>
		  <Route exact path="/profile">
           <ProfilePage />
         </Route>
		  <Route exact path="/account">
           <AccountPage />
         </Route>
      <Route exact path="/help">
          <HelpPage/>
          </Route>
      <Route exact path="/help/:id"
           render={(props) => <HelpPage {...props} />}
          />
		  <Route exact path="/invitations">
            <InvitationsPage />
          </Route>
      <Route exact path="/users/">
            <AllUsersPage />
      </Route>
      <Route exact path="/messages/:id" 
        render={(props) => <ConversationPage windowFocused={windowFocused} {...props} /> } 
        />
      <Route exact path="/user/:id" 
        render={(props) => <UserProfilePage {...props} /> } 
        />
          <Route path="">
          <NotFoundPage />
          </Route>
        </Switch>
    </Router>
    </>
  );
}

const mapStateToProps = state => {
  const { user , messages } = state
  return { user, messages };
}

const mapDispatchToProps = dispatch => {
  return {
    updateUserInfos: (userInfos) => dispatch(updateUserInfosAction(userInfos)),
    updateUsers: (users) => dispatch(updateUsersAction(users)),
    updateNotifications: (notifications, type) => dispatch(updateNotificationsAction(notifications, type)),
    updateCoversation: (conversationId, messages) => dispatch(updateCoversationAction(conversationId, messages)),
    loadCoversations: (conversations)  => dispatch(loadCoversationsActions(conversations)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);


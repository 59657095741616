import React from 'react';
import {connect} from 'react-redux';
import NotificationList from '../../Components/NotificationList/NotificationList';
import Button from '@material-ui/core/Button';
import {markNotificationAsReadAction, markNotificationAsDeleteAction} from '../../store/actions/notifications';

function NotificationsPage({ notifications, users, user, markNotificationAsRead, markNotificationAsDelete }) {
  const handleMarkAllAsRead = () => {
    notifications.notificationList.forEach( (el) =>{
      markNotificationAsRead(el);
    })
  }
  const handleMarkAllAsDelete = () => {
    notifications.notificationList.forEach( (el) =>{
      markNotificationAsDelete(el);
    })
  }
  return (
    <>
       <Button color="primary" onClick={handleMarkAllAsRead}>Mark all as read</Button>
       <Button color="primary" onClick={handleMarkAllAsDelete}>Delete all notifications</Button>
       <NotificationList notificationList={notifications.notificationList} users={users} myId={user.uid} markNotificationAsRead={markNotificationAsRead} markNotificationAsDelete={markNotificationAsDelete} />
    </>
  );
}

const mapStateToProps = state => {
  const { users, user, notifications } = state
  return { users, user, notifications };
}

const mapDispatchToProps = dispatch => {
  return {
    markNotificationAsRead: (notification) => dispatch(markNotificationAsReadAction(notification)),
    markNotificationAsDelete: (notification) => dispatch(markNotificationAsDeleteAction(notification)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(NotificationsPage);

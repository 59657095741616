import { combineReducers } from 'redux';
import user from './user';  
import users from './users';  
import messages from './messages';
import notifications from './notifications';
import search from './search';
import help from './help';
export default combineReducers({
  user,
  users,
  messages,
  notifications,
  search,
  help,
});

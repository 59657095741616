import Firebase from 'firebase';
export const SET_USER_ACTION = 'SET_USER_ACTION';
export const LOG_OUT_ACTION = 'LOG_OUT_ACTION';
export const LOG_IN_ACTION = 'LOG_IN_ACTION';
export const REGISTER_ACTION = 'REGISTER_ACTION';
export const UPDATE_USERNAME = 'UPDATE_USERNAME';
export const UPDATE_USER_PIC = 'UPDATE_USER_PIC';
export const UPDATE_USER_SEX = 'UPDATE_USER_SEX';
export const UPDATE_USER_AGE = 'UPDATE_USER_AGE';
export const UPDATE_USER_INFOS = 'UPDATE_USER_INFOS';
export const UPDATE_USER_ORIONTATION = 'UPDATE_USE_ORIONTATION';
export const UPDATE_USER_PHOTO = 'UPDATE_USER_PHOTO';
export const UPDATE_ABOUT_ME = 'UPDATE_ABOUT_ME';
export const RE_SEND_EMAIL_CONFIRMATION = 'RE_SEND_EMAIL_CONFIRMATION';
export const REST_PASSWORD_USER = 'REST_PASSWORD_USER';
export const setUserAction = (user) => ({
  type: SET_USER_ACTION,
  payload: user,
});
export const logOutAction = () =>({
  type: LOG_OUT_ACTION,
});

export const logInAction =  (email, password) =>
{
		return  async (dispatch) => {
		let errorMessage
		await Firebase.auth().signInWithEmailAndPassword(email, password)
		.catch(function(error) {
		errorMessage = error.message;
		dispatch({
			type: LOG_IN_ACTION,
			payload :{error : errorMessage},
			});
		});
	}
}
	
export const registerAction =  (email, password) =>
{
		return  async (dispatch) => {
		let errorMessage
		await Firebase.auth().createUserWithEmailAndPassword(email, password)
		.catch(function(error) {
		errorMessage = error.message;
		dispatch({
			type: REGISTER_ACTION,
			payload :{error : errorMessage},
			});
		});
		if(Firebase.auth().currentUser) Firebase.auth().currentUser.sendEmailVerification();
	}
}

export const reSendEmailConfirmationAction =  () =>
{
	return  async (dispatch) => {
		if(Firebase.auth().currentUser) Firebase.auth().currentUser.sendEmailVerification();
		dispatch({
			type: RE_SEND_EMAIL_CONFIRMATION,
			});	
}
}


export const restPasswordUserAction =  (email) =>
{
	console.log('resting password for user',email);
	return  async (dispatch) => {
		Firebase.auth().sendPasswordResetEmail(email).then(function() {
			dispatch({
				type: REST_PASSWORD_USER,
				payload : {error : `we sent a rest link to ${email}`},
				});	
		  }).catch(function(error) {
			var errorMessage = error.message;
		dispatch({
			type: REST_PASSWORD_USER,
			payload :{error : errorMessage},
			});
		});
		
}
}


	
export const updateUserNameAction = (displayName) =>{
	return  async (dispatch) => {
		if(Firebase.auth().currentUser) Firebase.auth().currentUser.updateProfile({
			displayName:  displayName,
			photoURL: Firebase.auth().currentUser.photoURL
			}).then(function() {
				Firebase.database().ref(`users/${Firebase.auth().currentUser.uid}/username`).set(displayName,(error) => {
					if (!error) {
				dispatch({
					type: UPDATE_USERNAME,
					payload :{displayName: displayName},
				});
			}})
			})
	}
}

export const updateUserSexAction = (sex, user) =>{
	return  async (dispatch) => {
	Firebase.database().ref(`users/${user.uid}/sex`).set(sex,(error) => {
		if (!error) 
			dispatch({
				type: UPDATE_USER_SEX,
				payload :{sex: sex},
			});
		})
	}
}

export const updateUserProfilePictureAction = (pic, user) =>{
	return  async (dispatch) => {
	Firebase.database().ref(`users/${user.uid}/pic`).set(pic,(error) => {
		if (!error) 
			dispatch({
				type: UPDATE_USER_PIC,
				payload :{pic: pic},
			});
		})
	}
}

export const updateUserAgeAction = (age, user) =>{
	return  async (dispatch) => {
	Firebase.database().ref(`users/${user.uid}/age`).set(age,(error) => {
		if (!error) 
			dispatch({
				type: UPDATE_USER_AGE,
				payload :{age: age},
			});
		})
	}
}

export const updateUserOrientationAction = (ori, user) =>{
	return  async (dispatch) => {
	Firebase.database().ref(`/users/${user.uid}/ori`).set(ori,(error) => {
		if (!error) 
			dispatch({
				type: UPDATE_USER_ORIONTATION,
				payload :{ori: ori},
			});
		})
	}
}

export const updateAboutMeAction = (abt, user) =>{
	return  async (dispatch) => {
	Firebase.database().ref(`/users/${user.uid}/abt`).set(abt,(error) => {
		if (!error) 
			dispatch({
				type: UPDATE_ABOUT_ME,
				payload :{abt: abt},
			});
		})
	}
}

export const updateUserPhotoURLAction = (photo, user) =>{
	return  async (dispatch) => {
	Firebase.database().ref(`/users/${user.uid}/photoURL`).set(photo,(error) => {
		if (!error) 
			dispatch({
				type: UPDATE_USER_PHOTO,
				payload :{photo: photo},
			});
		})
	}
}


export const updateUserInfosAction = (infos) =>({
		type: UPDATE_USER_INFOS,
		payload : infos,
	  });	

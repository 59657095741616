import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk'
import { createStore,  applyMiddleware  } from 'redux';
import reducer from './store/reducers';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './index.css';
import App from './App';
import Firebase from 'firebase';
import firebaseConfig from './firebaseConfig';
Firebase.initializeApp(firebaseConfig);
const store = createStore(reducer, applyMiddleware(thunkMiddleware));
  store.subscribe(()=>{
//	console.log('Store Update: ', /*store.getState()*/);
  });
const theme = createMuiTheme({
  /*
  palette: {
    common:{
      black: "#E00",
      white: "#0ff"
    },    
    primary: {
      light: '#ee00e8',
      main: '#ee00b5',
      dark: '#ee0084',
      contrastText: '#fff', // text primary
    },
    secondary: {
      light: '#ee0061',
      main: '#ee0036',
      dark: '#ee000d',
      contrastText: '#f00',
    },
    error: {
      light: '#ee0061',
      main: '#ee0036',
      dark: '#ee000d',
      contrastText: '#f00',
    },
    warning:  {
      light: '#ee0061',
      main: '#ee0036',
      dark: '#e000d',
      contrastText: '#f00',
    },
    info : {
      light: '#ee0061',
      main: '#ee0036',
      dark: '#ee000d',
      contrastText: '#f00',
    },
    success: {
      light: '#00e061',
      main: '#00e036',
      dark: '#00c00d',
      contrastText: '#f00',
    },
    text :{
      primary: "#f00",
      secondary: "#f00",
      disabled: "#f00",
      hint: "#f00",
    },
    background: {
      paper: "#fff",// color of cards
      default: "#ddd",//defaut background of pages
    },   
    action :{
      active: "#000",
      hover: "#e00",
      hoverOpacity: 0.04,
      selected: "#0e0",
      selectedOpacity: 0.08,
      disabled: "#00e",
      disabledBackground: "0e0",
      disabledOpacity: 0.38,
      focus: "#e0e",
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    themeName : 'CupidSpell',
  },
  */
});
  
ReactDOM.render(<Provider store={store}><ThemeProvider theme={theme}> <App /></ThemeProvider> </Provider>, document.getElementById('root'));


import React from 'react';
import Login from '../../Components/Login/Login';
function LoginPage() {
  return (
    <>
      <Login />
    </>
  );
}

export default LoginPage;

import React from 'react';
import Register from '../../Components/Register/Register';
function RegisterPage() {
  return (
    <>
      <Register />
    </>
  );
}

export default RegisterPage;

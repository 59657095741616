import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Input } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withRouter } from "react-router";
import { registerAction } from '../../store/actions/user';
import { connect } from 'react-redux';
import logo from '../../assets/images/logo.svg';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(theme => ({
  root: {
   
    },
  container :{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height : 'calc(100vh - 100px)'
  },
  card :{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding : 20,
  }
}));

const Register = ({ history, register, user }) => {
  const [error, setError] = useState('');
  const [formValid,setFormValid] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  useEffect(()=>{
	  if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) 
      && password.length >= 6 && password === passwordConfirmation) {
		  setFormValid(true);
		  setError('');
		  }else{
        setFormValid(false);
      }
	  if(email && !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))) setError('Please check your email');
	  else setError('');
      if(password && password.length < 6 ) setError('Password does max have at lease 6 caracters');
      if(password.length >= 6 &&  password !== passwordConfirmation) setError('Password does not match');
	 // console.log(password, passwordConfirmation, password !== passwordConfirmation);
  },[email,password, passwordConfirmation]);
  const RegisterUser = ()=>{
	register(email, password);
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && formValid) {
      RegisterUser();
    }
  } 

  const classes = useStyles();  
  return (
    <>
      <CssBaseline />
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ minHeight: 'calc(100vh - 65px)' }}
        >
        <Card className={classes.card}>
        <img alt="logo" src={logo} width="100" height="100" />
        <Typography variant='h4'>
          Register
        </Typography>
        <Input placeholder='Email' required type='email' value={email} onChange={event => setEmail(event.target.value)}  onKeyDown={(event)=> handleKeyDown(event)} />
		<br/>
        <Input placeholder='Password' required type='password' value={password} onChange={event => setPassword(event.target.value)}/>
		<br/>
    <Input placeholder='Confirme Password' required type='password' value={passwordConfirmation} onChange={event => setPasswordConfirmation(event.target.value)}  onKeyDown={(event)=> handleKeyDown(event)} />
		<br/>
		<Button variant="contained" color="primary" onClick={RegisterUser} disabled={!formValid}  style={{marginTop : '20px'}}>
		Register
		</Button>
		<br/>
		<Button color="primary" onClick={() => history.push('/login')}> You already have an account ?</Button>
		<br/>
		<Typography style={{color : 'red'}}>
		  {error}
		</Typography>
		<Typography style={{color : 'red'}}>
		  {user && user.error ? user.error : ''}
		</Typography>
      </Card>
      </Grid>
    </>
  );
}

const mapStateToProps = state => {
  const {user } = state;
  return { user };
}

const mapDispatchToProps = dispatch => {
  return {
    register: (email, password) => dispatch(registerAction(email, password)),
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Register));
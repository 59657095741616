import React from 'react';

function InvitationsPage() {
  return (
    <>
      InvitationsPage
    </>
  );
}

export default InvitationsPage;
